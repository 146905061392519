// icon:arrows_circle_left | Linea Iconset https://linea.io/ | Benjamin Sigidi

export default function ArrowCircleLeft(props) {
    return (
        <svg
            viewBox="0 0 64 64"
            fill="currentColor"
            height={props.height || '1em'}
            width={props.width || '1em'}
            {...props}
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="bevel"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M27 15l17 17-17 17"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M62.998999999999995 32 A30.999 30.999 0 0 1 32 62.998999999999995 A30.999 30.999 0 0 1 1.0010000000000012 32 A30.999 30.999 0 0 1 62.998999999999995 32 z"
            />
        </svg>
    )
}
