import { useEffect, useState, useRef, useCallback, useMemo } from 'react'

import FilterInput from './FilterInput'
import Graph from './Graph'
import Sidebar from './Sidebar'
import { debounce } from './utils'
import styles from './App.module.css'
import { SetFilterContext, SetInputContext } from './contexts'
import './global.css'
import trueanonLogo from './assets/trueanon_logo2.png'

const url = 'https://trueanon-vis-cb21724787f7.herokuapp.com/posts'
//const url = 'http://localhost:3000/posts'

const TAGS_BLACKLIST = ['guest', 'ben howard', 'noah kulwin', 'aaron good']

function App() {
    const [data, setData] = useState(null)
    const [filterString, setFilterString] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [selectedEpisode, setSelectedEpisode] = useState(null)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const sidebarContentRef = useRef()

    const handleToggleSidebar = () => {
        if (isSidebarOpen) {
            setIsSidebarOpen(false)
        } else {
            setIsSidebarOpen(true)
        }
        sidebarContentRef.current?.scrollTo(0, 0)
    }
    const handleSelectEpisode = useCallback((episode) => {
        setIsSidebarOpen(true)
        setSelectedEpisode(episode)
        setIsImageLoaded(false)
    }, [])

    const setSearch = useMemo(() => {
        return debounce((val) => setFilterString(val), 300)
    }, [])

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        const sanitizedString = e.target.value.replace(/["]/g, '')
        setSearch(sanitizedString.toLowerCase())
    }

    const handleClearFilter = () => {
        setFilterString('')
        setInputValue('')
    }

    const containerRef = useRef()

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const fetchData = async () => {
            const data = await global.fetch(url, { method: 'GET', signal })
            const json = await data.json()
            setData(json)
        }
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])

    const placeholderList = useMemo(() => {
        if (!data) {
            return []
        }
        const result = Object.keys(data.tags)
            .toSorted((a, b) => data.tags[b] - data.tags[a])
            .slice(0, 30)
            .filter((tag) => !TAGS_BLACKLIST.includes(tag))
            .map((tag) => `Search "${tag}"...`)
        return result
    }, [data])

    return (
        <SetFilterContext.Provider value={setFilterString}>
            <SetInputContext.Provider value={setInputValue}>
                <div className={styles.header}>
                    <span>
                        the podcast |{' '}
                        <a href="http://trueanon.com">the store</a>
                    </span>
                </div>
                <div className={styles.container}>
                    <Sidebar
                        isOpen={isSidebarOpen}
                        episode={selectedEpisode}
                        handleToggle={handleToggleSidebar}
                        filterString={filterString}
                        sidebarContentRef={sidebarContentRef}
                        isImageLoaded={isImageLoaded}
                        setIsImageLoaded={setIsImageLoaded}
                    />

                    {data ? (
                        <div className={styles.controls}>
                            <FilterInput
                                handleChange={handleInputChange}
                                handleClear={handleClearFilter}
                                inputValue={inputValue}
                                placeholderList={placeholderList}
                            />
                        </div>
                    ) : null}
                    <div className={styles.graph} ref={containerRef}>
                        {data ? (
                            <Graph
                                data={data}
                                containerRef={containerRef}
                                filterString={filterString}
                                handleSelect={handleSelectEpisode}
                            />
                        ) : (
                            <div className={styles.loading}>
                                <img
                                    src={trueanonLogo}
                                    className={styles.spinner}
                                />
                                Loading...
                            </div>
                        )}
                    </div>
                </div>
            </SetInputContext.Provider>
        </SetFilterContext.Provider>
    )
}

export default App
