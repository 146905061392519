export function debounce(func, timeout = 300) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

export function highlightHtmlContent(htmlString, filterString, highlightClass) {
    if (!filterString.trim()) {
        return htmlString
    }

    const escapedFilterString = filterString.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
    )
    const highlightRegExp = new RegExp(`(${escapedFilterString})`, 'gi')
    const parser = new global.DOMParser()
    const serializer = new global.XMLSerializer()
    const doc = parser.parseFromString(htmlString, 'text/html')

    function collectMatches(node) {
        const matches = []
        if (node.nodeType === 3) {
            // Node.TEXT_NODE
            let match
            while ((match = highlightRegExp.exec(node.nodeValue)) !== null) {
                matches.push({
                    node,
                    match: match[0],
                    index: match.index,
                })
            }
        } else if (node.nodeType === 1) {
            // Node.ELEMENT_NODE, recurse for its children
            node.childNodes.forEach((child) => {
                matches.push(...collectMatches(child))
            })
        }
        return matches
    }

    function highlightMatches(matches) {
        for (let i = matches.length - 1; i >= 0; i--) {
            // Process from end to start
            const { node, match, index } = matches[i]
            const afterText = node.splitText(index + match.length)
            const highlightSpan = global.document.createElement('span')
            highlightSpan.className = highlightClass
            highlightSpan.textContent = match

            const matchNode = node.splitText(index)
            node.parentNode.replaceChild(highlightSpan, matchNode)

            // Re-insert afterText (which is now effectively the text after the highlight)
            highlightSpan.parentNode.insertBefore(
                afterText,
                highlightSpan.nextSibling
            )
        }
    }

    const matches = collectMatches(doc.body)
    highlightMatches(matches)

    return serializer
        .serializeToString(doc.body)
        .replace(/^<body>|<\/body>$/gi, '') // Remove <body> tags added by DOMParser
}

export function highlightContent({
    text,
    filterString,
    highlightClass,
    exactMatch,
}) {
    if (!filterString.trim()) {
        return text
    }

    if (exactMatch && text === filterString) {
        return <span className={highlightClass}>{text}</span>
    } else if (exactMatch) {
        return text
    }
    const escapedFilterString = filterString.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
    )
    const parts = text.split(new RegExp(`(${escapedFilterString})`, 'gi'))
    return (
        <span>
            {parts.map((part, i) =>
                part.toLowerCase() === filterString.toLowerCase() ? (
                    <span key={i} className={highlightClass}>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </span>
    )
}

export const constructImageVariantUrl = (episode, variant) => {
    return episode.thumbnail.split('/').slice(0, -1).concat([variant]).join('/')
}
