// icon:close-outline | Ionicons https://ionicons.com/ | Ionic Framework

function IconCloseOutline(props) {
    return (
        <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            height={props.height || '1em'}
            width={props.width || '1em'}
            {...props}
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M368 368L144 144M368 144L144 368"
            />
        </svg>
    )
}

export default IconCloseOutline
