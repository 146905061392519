import { useEffect, useRef } from 'react'
import { format } from 'date-fns'
import {
    highlightHtmlContent,
    highlightContent,
    constructImageVariantUrl,
} from './utils'

import styles from './Sidebar.module.css'
import ArrowCircleLeft from './icons/ArrowCircleLeft'
import Tags from './Tags'
import trueanonLogo from './assets/trueanon_logo2.png'

export default function Sidebar({
    episode,
    isOpen,
    handleToggle,
    filterString,
    sidebarContentRef,
    isImageLoaded,
    setIsImageLoaded,
}) {
    const imageRef = useRef(null)
    useEffect(() => {
        if (isOpen && imageRef.current && imageRef.current.complete) {
            setIsImageLoaded(true) // If the image is already loaded, update the state
        }
    }, [isOpen, setIsImageLoaded])

    const renderContent = () => {
        if (episode.content) {
            const highlightedHtml = highlightHtmlContent(
                episode.content,
                filterString,
                styles.highlight
            )
            return (
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: highlightedHtml,
                    }}
                />
            )
        } else if (episode.teaser_text) {
            return (
                <>
                    <div className={styles.description}>
                        {highlightContent({
                            text: episode.teaser_text,
                            filterString,
                            highlightClass: styles.highlight,
                        })}
                        ...{' '}
                    </div>
                    <span className={styles.subscribers}>
                        (available to Patreon subscribers)
                    </span>
                </>
            )
        }
        return null
    }

    let episodeContent
    if (episode?.type === 'episode') {
        episodeContent = (
            <>
                <h1 className={styles.title}>
                    {highlightContent({
                        text: episode.title,
                        filterString,
                        highlightClass: styles.highlight,
                    })}
                </h1>

                <h3>{format(episode.published_at, 'MM.d.yy')}</h3>
                <div className={styles.contentWrapper}>
                    <img
                        className={`${styles.thumbnail} ${isImageLoaded ? '' : styles.blur}`}
                        src={constructImageVariantUrl(episode, 'sidebar')}
                        ref={imageRef}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                    {renderContent()}
                    {episode.tags.length > 0 && (
                        <Tags tags={episode.tags} filterString={filterString} />
                    )}
                    <button>
                        <a target="_blank" rel="noreferrer" href={episode.url}>
                            Listen on Patreon
                        </a>
                    </button>
                </div>
            </>
        )
    } else {
        episodeContent = (
            <>
                <h1 className={styles.title}>TrueAnon</h1>
                <div className={styles.contentWrapper}>
                    <img className={styles.thumbnail} src={trueanonLogo} />
                    <div className={styles.description}>
                        TrueAnon is a podcast about your enemies made by your
                        friends. Join unlicensed private investigators Liz
                        Franczak, Brace Belden, and Yung Chomsky for a show that
                        will drive you insane. Every other episode is a Patreon
                        exclusive.
                    </div>
                    <button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="http://patreon.com/TrueAnonPod"
                        >
                            Listen on Patreon
                        </a>
                    </button>
                </div>
            </>
        )
    }

    return (
        <div
            className={`${styles.container} ${isOpen ? styles.expanded : styles.collapsed}`}
        >
            <button className={styles.toggle} onClick={handleToggle}>
                <ArrowCircleLeft width="30px" height="30px" />
            </button>

            <div className={styles.episode} ref={sidebarContentRef}>
                {episodeContent}
            </div>
        </div>
    )
}
