import { useRef, useState, useEffect } from 'react'
import Close from './icons/Close'
import styles from './FilterInput.module.css'

export default function FilterInput({
    handleChange,
    handleClear,
    inputValue,
    placeholderList,
}) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [animate, setAnimate] = useState(false)
    const inputRef = useRef()
    const clearInput = () => {
        inputRef.current.value = ''
        handleClear()
    }
    useEffect(() => {
        const intervalId = global.setInterval(() => {
            setAnimate(true)
            setTimeout(() => {
                setCurrentIndex((currentIndex + 1) % placeholderList.length)
                setAnimate(false)
            }, 500)
        }, 3000)
        return () => clearInterval(intervalId)
    }, [animate, placeholderList.length, currentIndex])
    return (
        <div className={styles.container}>
            <div className={styles.instruction}>
                Search episode titles, descriptions, or tags
            </div>
            <div className={styles.inputWrapper}>
                <span
                    className={`${styles.placeholder} ${animate ? styles.animate : ''}`}
                >
                    {!inputValue && placeholderList[currentIndex]}
                </span>

                <input
                    className={styles.filter}
                    type="text"
                    onChange={handleChange}
                    ref={inputRef}
                    value={inputValue}
                    placeholder=""
                />

                {inputValue.length > 0 ? (
                    <button className={styles.closeButton} onClick={clearInput}>
                        <Close
                            width="20px"
                            height="20px"
                            className={styles.closeIcon}
                        />
                    </button>
                ) : null}
            </div>
        </div>
    )
}
