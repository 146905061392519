import * as d3 from 'd3'
import { useState, useEffect, useRef, useCallback } from 'react'
import styles from './Graph.module.css'
import { debounce, constructImageVariantUrl } from './utils'
import { useNavigate, useLocation } from 'react-router-dom'

import trueanonLogo from './assets/trueanon_logo.png'

const IMAGE_SIZE = 30
const PODCAST_SIZE = 100
const EPISODE_RING_RADIUS = 300

export default function Graph({
    data,
    containerRef,
    filterString,
    handleSelect,
}) {
    const [dimensions, setDimensions] = useState({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
    })
    const [isRendered, setIsRendered] = useState(false)
    const graphRef = useRef()
    const navigate = useNavigate()
    const location = useLocation()

    const handleResize = debounce(() => {
        setDimensions({
            width: containerRef.current.clientWidth,
            height: containerRef.current.clientHeight,
        })
    }, 250)
    function handleMouseEnter() {
        d3.select(this).classed(styles.hovered, true)
    }

    function handleMouseLeave() {
        d3.select(this).classed(styles.hovered, false)
    }

    const selectNodeById = useCallback(
        (nodeId) => {
            const nodes = d3.selectAll(`.${styles.node}`)
            const links = d3.selectAll('line')

            // Reset all selections and active classes
            nodes.classed(styles.selected, false)
            links.classed(styles.active, false)

            // Find the node and the corresponding link
            const node = nodes.filter((d) => d.id === nodeId).node()
            if (node) {
                d3.select(node).classed(styles.selected, true)
                handleSelect(d3.select(node).datum())

                // Activate the corresponding line
                links
                    .filter((link) => link.source.id === nodeId)
                    .classed(styles.active, true)
            }
        },
        [handleSelect]
    )

    useEffect(() => {
        const hash = location.hash.replace('#', '')
        if (hash && isRendered) {
            selectNodeById(hash)
        }
    }, [location, selectNodeById, data, isRendered])

    useEffect(() => {
        global.window.addEventListener('resize', handleResize)

        return () => {
            global.window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    useEffect(() => {
        d3.select(graphRef.current).selectAll('*').remove()
        setIsRendered(false)

        function handleClick(_, d) {
            if (!d3.select(this).classed(styles.inactive)) {
                navigate(`#${d.id}`) // Changes the URL hash, triggering the effect
            }
        }

        const podcastNode = {
            name: 'TrueAnon',
            type: 'podcast',
            imageUrl: trueanonLogo,
            fx: dimensions.width / 2,
            fy: dimensions.height / 2,
            id: 'trueanon',
        }

        const episodeNodes = data.posts.map((episode, index, { length }) => {
            const angleIncrement = (2 * Math.PI) / length
            const angle = angleIncrement * index
            episode.x =
                dimensions.width / 2 + Math.cos(angle) * EPISODE_RING_RADIUS
            episode.y =
                dimensions.height / 2 + Math.sin(angle) * EPISODE_RING_RADIUS

            return {
                ...episode,
                name: episode.id,
                type: 'episode',
                imageUrl: constructImageVariantUrl(episode, 'graph'),
            }
        })

        const links = episodeNodes.map((e) => ({
            source: e,
            target: podcastNode,
        }))

        const nodes = [podcastNode, ...episodeNodes]

        const svg = d3
            .select(graphRef.current)
            .attr('width', dimensions.width)
            .attr('height', dimensions.height)

        const container = svg.append('g')

        const zoom = d3
            .zoom()
            .scaleExtent([0.1, 10])
            .on('zoom', (event) => {
                container.attr('transform', event.transform)
            })

        svg.call(zoom)

        const simulation = d3
            .forceSimulation(nodes)
            .force(
                'center',
                d3.forceCenter(dimensions.width / 2, dimensions.height / 2)
            )
            .force(
                'collide',
                d3.forceCollide((d) => {
                    switch (d.type) {
                        case 'podcast':
                            return PODCAST_SIZE + 5
                        case 'episode':
                            return IMAGE_SIZE / 2 + 5
                    }
                })
            )

        const link = container
            .append('g')
            .selectAll('line')
            .data(links)
            .enter()
            .append('line')
            .attr('class', styles.link)

        const node = container
            .append('g')
            .attr('class', 'nodes')
            .selectAll(`.${styles.node}`)
            .data(nodes)
            .enter()
            .append('g')
            .attr('class', styles.node)

        node.on('mouseenter', handleMouseEnter)
        node.on('mouseleave', handleMouseLeave)
        node.on('click', handleClick)

        const getSize = (d) =>
            d.type === 'episode' ? IMAGE_SIZE : PODCAST_SIZE

        node.each(function (d) {
            const selection = d3.select(this)

            if (d.type === 'episode') {
                selection
                    .append('rect')
                    .attr('width', getSize)
                    .attr('height', getSize)
                    .attr('x', (d) => -(IMAGE_SIZE / 2))
                    .attr('y', (d) => -(IMAGE_SIZE / 2))
                    .attr('class', styles.node)
            }

            selection
                .append('image')
                .attr('xlink:href', (d) => d.imageUrl)
                .attr('width', getSize)
                .attr('height', getSize)
                .attr('x', (d) => -(getSize(d) / 2))
                .attr('y', (d) => -(getSize(d) / 2))
                .append('title')
                .text((d) => (d.type === 'episode' ? d.title : 'TrueAnon'))
        })

        simulation.on('tick', () => {
            link.attr('x1', (d) => d.source.x)
                .attr('y1', (d) => d.source.y)
                .attr('x2', (d) => d.target.x)
                .attr('y2', (d) => d.target.y)

            node.attr('transform', (d) => `translate(${d.x},${d.y})`)
        })

        svg.call(zoom)
        setIsRendered(true)
    }, [dimensions, handleSelect, navigate, data])

    useEffect(() => {
        const episodeTest = (d) => {
            if (filterString.startsWith('tag:') && filterString.length > 4) {
                const searchString = filterString.slice(4)
                return d.tags.some((tag) => tag.toLowerCase() === searchString)
            }
            return (
                d.title.toLowerCase().includes(filterString) ||
                d.content?.toLowerCase().includes(filterString) ||
                d.teaser_text?.toLowerCase().includes(filterString) ||
                d.tags.some((tag) => tag.toLowerCase().includes(filterString))
            )
        }

        const lines = d3.selectAll('line')
        const nodes = d3
            .selectAll(`.${styles.node}`)
            .filter((d) => d.type === 'episode')

        if (filterString) {
            lines.attr('class', (d) =>
                episodeTest(d.source)
                    ? `${styles.link} ${styles.active}`
                    : `${styles.link} ${styles.inactive}`
            )
            nodes.attr('class', (d) =>
                episodeTest(d)
                    ? `${styles.node} ${styles.active}`
                    : `${styles.node} ${styles.inactive}`
            )
        } else {
            lines.attr('class', styles.link)
            nodes.attr('class', styles.node)
        }
    }, [filterString, dimensions])

    return <svg ref={graphRef}></svg>
}
