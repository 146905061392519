import { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import styles from './Stats.module.css'

const url = 'https://trueanon-vis-cb21724787f7.herokuapp.com/posts'
//const url = 'http://localhost:3000/posts'

export default function Stats() {
    const [data, setData] = useState(null)
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const fetchData = async () => {
            const data = await global.fetch(url, { method: 'GET', signal })
            const json = await data.json()
            const { posts } = json
            const results = {}
            for (let i = 0; i < posts.length; i++) {
                const post = posts[i]
                const isPatreon = !post.content
                const monthAndYear = format(
                    parseISO(post.published_at),
                    'MMMM y'
                )
                if (results[monthAndYear]) {
                    results[monthAndYear].total++
                    if (isPatreon) {
                        results[monthAndYear].patreon++
                    }
                } else {
                    results[monthAndYear] = {
                        total: 1,
                        patreon: isPatreon ? 1 : 0,
                    }
                }
            }
            const months = Object.keys(results)
            const sortedMonths = months.sort(
                (a, b) => new Date(b) - new Date(a)
            )
            const sortedResults = sortedMonths.map((m) => ({
                ...results[m],
                month: m,
            }))
            setData(sortedResults)
        }
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>TrueAnon Episodes</h2>
            {!data ? (
                <div>Loading...</div>
            ) : (
                data.map((d) => (
                    <div className={styles.result} key={d.month}>
                        <span className={styles.month}>{d.month}</span>
                        <div className={styles.details}>
                            <div>
                                <span className={styles.total}>
                                    {d.total} total
                                </span>
                            </div>
                            <div>
                                <span className={styles.patreon}>
                                    {d.patreon} patreon
                                </span>
                            </div>
                            <div>
                                <span className={styles.public}>
                                    {d.total - d.patreon} public
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}
