import { useContext } from 'react'
import styles from './Tags.module.css'
import { SetFilterContext, SetInputContext } from './contexts'
import { highlightContent } from './utils'

function Tag({ tag, filterString }) {
    const setFilterString = useContext(SetFilterContext)
    const setInputString = useContext(SetInputContext)
    const handleClick = () => {
        const str = `tag:${tag}`
        setFilterString(str)
        setInputString(str)
    }
    const isTagSearch = filterString.startsWith('tag:')
    const processedString = isTagSearch ? filterString.slice(4) : filterString
    return (
        <button className={styles.tagButton} onClick={handleClick}>
            <span className={styles.tag}>
                {highlightContent({
                    text: tag,
                    filterString: processedString,
                    highlightClass: styles.highlight,
                    exactMatch: isTagSearch,
                })}
            </span>
        </button>
    )
}

export default function Tags({ tags, filterString }) {
    return (
        <div className={styles.container}>
            {tags.map((tag, i) => (
                <Tag filterString={filterString} key={i} tag={tag} />
            ))}
        </div>
    )
}
